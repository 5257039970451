<template>
  <el-col class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>营销云</el-breadcrumb-item>
           <el-breadcrumb-item>分析</el-breadcrumb-item>
           <el-breadcrumb-item>活动分析</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>


     <el-row class="reten-full">
        <el-row class="reten-title">参与各类促销活动用户分布</el-row>
        <el-row class="row-data-box">
           <el-col style="width:77.2%">销售统计</el-col>
           <el-col style="width:22%">
              <el-date-picker
                    style="width:100%"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="orderDateVal"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="getOrderDatePacker">
              </el-date-picker>
           </el-col>
        </el-row>
        <el-row class="box">
           <el-row id="echart" style="width: 70%; height: 300px"></el-row>
           <el-row class="information">
              <el-row style="margin-left: 0.7rem;">{{data2}}</el-row>
              <el-row style="display:flex;" v-for="(item,index) in today" :key="index">
                 <span style="display: inline-block;margin-left:0.7rem;width:50%;">{{item.eventName}}</span>
                 <span>{{item.todayBuyCount}}</span></el-row>
              </el-row>
        </el-row>
     </el-row>

     <el-row class="reten-full">
        <el-row class="reten-title">各类活动总销售</el-row>
        <el-row class="row-data-box">
           <el-col :span="18">过去七日，按日，所有用户</el-col>
           <el-col :span="6">
              <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="orderDateVals"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="getOrderDatePackers">
              </el-date-picker>
           </el-col>
        </el-row>
        <el-table :data="tableData" style="width: 100%">
           <el-table-column prop="eventName" sortable label="活动类型" ></el-table-column>
           <el-table-column sortable label="合计">
              <template slot-scope="scope"><p>{{scope.row.buyCount}}</p></template>
           </el-table-column>
           <el-table-column prop="date" sortable :label="data2" >
              <!--          <template slot-scope="scope"><p>{{scope.row}}</p></template>-->
           </el-table-column>
        </el-table>
     </el-row>
  </el-col>
</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
import { dateFactory } from "@/common/js/common";
export default {
  watch: {
      hotelInfo: {
        handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
              this.hotelId = this.hotelInfo.id
              this.aa()
              this.timead()
            }
        },
        deep: true
      }
  },
  computed: {
      ...mapState(['hotelInfo'])
  },
  data() {
    return {
      limit: 20,
      page: 1,
      orderDateVal: [],
      orderDateVals:[],
      tableData: [],
      option : {
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
        {
          type: 'category',
          data: [],
          minInterval: 1,
          axisTick: {
              alignWithLabel: true
            },
        }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data: []
          }
        ]
      },

      orderBeginTime:'',
      orderEndTime:'',
      orderBeginTimes:'',
      orderEndTimes:'',
      date:'',
      today:[],
      date2:'',
      data2:'',
      hotelId:'',
    };
  },
  mounted() {
    this.hotelId = this.hotelInfo.id
     this.date2 = dateFactory.dateFormat()
     let times = dateFactory.getDistanceAppoint(this.date2, '-', 7, true);
     this.orderDateVal = [times,this.date2]
     this.orderDateVals = [times,this.date2]
     this.orderBeginTime = this.orderBeginTimes = times
     this.orderEndTime =  this.orderEndTimes = this.date2
     this.userdistribution();
     this.totalsales()
  },
  methods: {

    // 工单统计时间段过滤
    getOrderDatePacker(val) {
      this.orderDateVal = val;
      if (this.orderDateVal) {
        this.orderBeginTime = this.orderDateVal[0];
        this.orderEndTime = this.orderDateVal[1];
      } else {
        this.orderBeginTime = "";
        this.orderEndTime = "";
      }
      this.userdistribution()
    },
      // 工单统计时间段过滤
      getOrderDatePackers(val) {
          this.orderDateVals = val;
        // console.log(this.orderDateVals)

          if (this.orderDateVals) {
              this.orderBeginTimes = this.orderDateVals[0];
              this.orderEndTimes = this.orderDateVals[1];
          } else {
              this.orderBeginTimes = "";
              this.orderEndTimes = "";
          }
          this.totalsales()
      },
    //参与各类促销活动用户分布
    userdistribution() {
      this.option.series[0].data = []
      this.option.xAxis[0].data = []
      var myChart = this.$echarts.init(document.getElementById("echart"));
      const url = urlObj.salesPromotionUser;
      const param = {
        hotelId:this.hotelId,
        beginTime:this.orderBeginTime,
        endTime:this.orderEndTime,
        date:this.data2
      };
      this.$axios.get(url, param).then((res) => {
        // console.log(res);
          this.today = res.records
          this.today.forEach(item=>{
            this.option.xAxis[0].data.push(item.eventName)
            this.option.series[0].data.push(item.buyCount)
          })
        myChart.setOption(this.option);
      });
    },

      //各类活动总销售
      totalsales(){
          const url = urlObj.salesStatisticsas;
          const param = {
              hotelId:this.hotelId,
              beginTime:this.orderBeginTimes,
              endTime:this.orderEndTimes,
          };
          this.$axios.get(url, param,{'Content-Type':"applicaton/x-www-form-urlencoded;charset=UTF-8"}).then((res) => {
              // console.log(res);
              this.tableData = res.records
          });
          // console.log(this.tableData)
      },
  },
};
</script>

<style scoped  lang="scss">
.reten-full{
   margin: 20px 20px;
   background-color: white;
   box-shadow: 0px 0px 20px #dce7f9;
   .reten-title{
      height: 50px;
      line-height: 50px;
      background: #f7f7f8;
      padding: 0px 20px;
      text-align: left;
      font-size:18px;
      i{
         float: right;
         display: inline-block;
         font-size: 20px;
         padding: 1rem;
      }
   }
   .row-data-box{
      height: 50px;
      line-height: 50px;
      padding: 0px 30px 0 20px;
   }
   .box{
      margin: 20px 0px;
   }
   .information {
      width: 22%;
      height: 300px;
      border: 1px solid #e5e5e5;
      float: right;
      margin-right: 2rem;
      overflow-y: scroll;
   }
}






.participate {
  width: 95%;
  height: 450px;
  margin: auto;
  margin-top: 1rem;
  box-shadow: 0px 0px 20px #dce7f9;
   background: #FFFFFF;
}
.yellow {
  width: 10px;
  height: 10px;
  background: #ffb732;
  border-radius: 50%;
  margin: 0px 0.5rem;
}
.res {
  width: 10px;
  height: 10px;
  background: #ee7164;
  border-radius: 50%;
  margin: 0px 0.5rem;
}
.blue {
  width: 10px;
  height: 10px;
  background: #087aff;
  border-radius: 50%;
  margin: 0px 0.5rem;
}
.green {
  width: 10px;
  height: 10px;
  background: #34d7e3;
  border-radius: 50%;
  margin: 0px 0.5rem;
}
.purple {
  width: 10px;
  height: 10px;
  background: #b205b3;
  border-radius: 50%;
  margin: 0px 0.5rem;
}

.salesstatistics {
  width: 98%;
  margin: auto;
  display: flex;
}
.salesstatistics .el-row {
  width: 50%;
  font-size: 15px;
}
.tiem {
  text-align: right;
}

#echart {
  float: left;
}
.information .el-row {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}

.totalsales {
  width: 95%;
  height: 450px;
  margin: auto;
  margin-top: 1rem;
  box-shadow: 0px 0px 20px #dce7f9;
   background: #FFFFFF;
}
/deep/ .has-gutter tr th {
  text-align: center;
}
/deep/ tbody .el-table__row td {
  text-align: center;
}
/deep/ .el-table__header {
  border-bottom: 1px solid #399eff;
}
@media screen and (max-width: 1366px) {
  .main {
    width: 1300px;
  }
}
</style>
